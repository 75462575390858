.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 300px;
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
  background-color: #000000;
  transform-origin: center;
  border-radius: 16px;
}

.container:hover .overlay {
  opacity: 1;
  transform: rotateY(360deg);
}

.text {
  padding: 48px 24px;
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  border: 1px solid #1F1D31;
  height: 100%;
  border-radius: 16px;
  background:
    linear-gradient(136.94deg, rgba(62, 56, 102, 0.4) 24.09%, rgba(62, 56, 102, 0) 114.67%);
}

@media only screen and (max-width: 1023px) {
  .overlay {
    width: 250px;
    border-radius: 8px;
  }
}

@media only screen and (max-width: 800px) {
  .overlay {
    width: 200px;
  }

  .text {
    padding: 12px 8px;

  }
}