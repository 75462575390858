@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

.social-icon:hover img {
  filter: invert(42%) sepia(83%) saturate(2264%) hue-rotate(340deg) brightness(91%) contrast(88%);
}